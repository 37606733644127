import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faInfo,
  faSearch,
  faCopy,
  faCheck,
  faTimes,
  faClock,
  faChevronUp,
  faChevronDown,
  faArrowUp,
  faArrowDown,
  faArrowsAltH,
  faChild,
  faUsersSlash,
  faUsers,
  faAddressCard,
  faTrafficLight,
  faCog,
  faChartBar,
  faBookOpen,
  faChartLine,
  faInfoCircle,
  faCalendarCheck,
  faCheckDouble,
  faLaptopCode,
  faHardHat,
  faPassport,
  faTrash,
  faCompressAlt,
  faArrowAltCircleRight,
  faListAlt,
  faIndustry,
  faBars,
  faCheckCircle,
  faLaptopHouse,
  faQuestion,
  faChartPie,
  faSort,
  faSortUp,
  faBacteria,
  faSortDown,
  faStopwatch,
  faPaperPlane,
  faStoreSlash,
  faEnvelope,
  faDownload,
  faPiggyBank,
  faColumns,
  faBuildingColumns,
  faMoneyBill,
  faHourglass,
  faPerson,
  faBagShopping,
  faBolt,
  faEye,
  faQrcode,
  faMobile,
  faCaretRight,
  faCaretDown,
  faGavel,
  faCoins,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faTrafficLight,
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faInfo,
  faSearch,
  faCopy,
  faCheck,
  faTimes,
  faClock,
  faChevronUp,
  faChevronDown,
  faArrowUp,
  faArrowDown,
  faArrowsAltH,
  faChild,
  faUsersSlash,
  faUsers,
  faAddressCard,
  faBookOpen,
  faChartLine,
  faInfo,
  faInfoCircle,
  faCalendarCheck,
  faCheckDouble,
  faBacteria,
  faLaptopCode,
  faHardHat,
  faPassport,
  faTrash,
  faCompressAlt,
  faArrowAltCircleRight,
  faCog,
  faChartBar,
  faListAlt,
  faIndustry,
  faBars,
  faCheckCircle,
  faLaptopHouse,
  faQuestion,
  faChartPie,
  faSort,
  faSortUp,
  faSortDown,
  faStopwatch,
  faPaperPlane,
  faStoreSlash,
  faEnvelope,
  faDownload,
  faPiggyBank,
  faColumns,
  faBuildingColumns,
  faMoneyBill,
  faHourglass,
  faPerson,
  faBagShopping,
  faBolt,
  faEye,
  faQrcode,
  faMobile,
  faCaretRight,
  faCaretDown,
  faGavel,
  faCoins,
);

export { FontAwesomeIcon };
